var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "700" },
      on: { "click:outside": _vm.closeModal },
      model: {
        value: _vm.dialogUserEdit,
        callback: function($$v) {
          _vm.dialogUserEdit = $$v
        },
        expression: "dialogUserEdit"
      }
    },
    [
      _c("v-card", { staticClass: "popup add_store_popup" }, [
        _c("h3", { staticClass: "page-title-bar" }, [
          _c("i", { staticClass: "ico ico-user" }),
          _vm._v(" " + _vm._s(_vm.$t("User Edit")) + " ")
        ]),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  { attrs: { cols: "6" } },
                  [
                    _c("v-select", {
                      staticClass: "form-select",
                      attrs: {
                        label: _vm.$t("Approval"),
                        placeholder: _vm.$t("Select the Approval"),
                        items: _vm.approvalItems,
                        outlined: "",
                        dense: "",
                        "hide-details": ""
                      },
                      model: {
                        value: _vm.approval,
                        callback: function($$v) {
                          _vm.approval = $$v
                        },
                        expression: "approval"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        label: _vm.$t("Email Address"),
                        placeholder: _vm.$t("Input the email address"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        disabled: ""
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  [
                    _c("v-text-field", {
                      staticClass: "form-input",
                      attrs: {
                        label: "" + _vm.$t("Name"),
                        placeholder: _vm.$t("Input the name"),
                        outlined: "",
                        dense: "",
                        "hide-details": "",
                        disabled: ""
                      },
                      model: {
                        value: _vm.name,
                        callback: function($$v) {
                          _vm.name = $$v
                        },
                        expression: "name"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  [
                    _c("p", { staticClass: "label-txt" }, [
                      _vm._v(_vm._s(_vm.$t("User Level")))
                    ]),
                    _c(
                      "v-radio-group",
                      {
                        attrs: { dense: "", "hide-details": "", row: "" },
                        model: {
                          value: _vm.selectLevel,
                          callback: function($$v) {
                            _vm.selectLevel = $$v
                          },
                          expression: "selectLevel"
                        }
                      },
                      _vm._l(_vm.accessLevelItems, function(item) {
                        return _c("v-radio", {
                          key: item.level,
                          staticClass: "form-radio flat",
                          attrs: { value: item.level, label: item.name }
                        })
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "v-card-actions",
              { staticClass: "d-flex justify-center" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { text: "", icon: "" },
                    on: { click: _vm.clickSave }
                  },
                  [_vm._v(_vm._s(_vm.$t("Save")))]
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "btn",
                    attrs: { text: "", icon: "" },
                    on: { click: _vm.closeModal }
                  },
                  [_vm._v(_vm._s(_vm.$t("Cancel")))]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }